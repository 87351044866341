import { css } from 'styled-components'

const sizes = {
    giant: 1170,
    desktop: 992,
    tablet: 768,
    phone: 376
}

export const media = Object.keys(sizes).reduce((accumulator, label) => {
    const emSize = sizes[label] / 18
    accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)}
    }
  `
    return accumulator
}, {})
