import { createGlobalStyle } from 'styled-components'
import MainBg from '../images/background.png'
import { media } from "../style-utils/media";

const GlobalStyle = createGlobalStyle`

  html {
    box-sizing: border-box;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
  }

  *,
  ::before,
  ::after {
    background-repeat: no-repeat;
    box-sizing: inherit;
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
  }
  body {
    backface-visibility: hidden;
    background: url(${MainBg});
    color: #fff;
    letter-spacing: 3px;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
  }
  h1 {
    font-size: 5rem;
    font-weight: normal;
    ${media.tablet`
      font-size: 2.9rem;
    `};
  }
  h4 {
    font-size: 5rem;
    font-weight: normal;
  }
  h2 {
    font-size: 2rem;
    font-weight: normal;
    text-align: center;
    ${media.tablet`
      font-size: 1.8rem;
    `};
  }
  button:focus {outline:0;}
  ul {
    list-style-type: none !important;
  }
  nav {
    list-style-type: none !important;
  }
  p {
    text-indent: 4ch;
  }
  a {
    text-decoration: none;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-items: stretch;
    padding: 5px;
  }
  a, a:visited, a:hover, a:active {
    color: inherit;
  }
  section {
    width: 100%;
    height: 100vh;
    display: grid;
    overflow: scroll;
    text-shadow: 1px 1px #3b4199;
  }
  input, textarea{
    width: 100%;
    border-radius: 5px;
    border: 1px inset;
    padding: 5px;
  }
  input {
    height: 3rem;
  }

  button {
    color: #fff;
    width: 100%;
    height: 3rem;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #494949;
    font-size: 20px;
      &:hover {
      background: #27bd6e;
      & > * {
      filter: drop-shadow(0px 1px 1px rgba(59, 65,153, 1));
      }
    }
  }
  .page {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    width: 100vw;
  }

  .grid-center{
    display: grid;
    justify-items: center;
    justify-content: center;
  }
`;

export default GlobalStyle
