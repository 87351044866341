import React from 'react'
import Jeff from '../icons/jeff.png'
import styled from 'styled-components'
import { useSpring, animated, config } from "react-spring";

const LoadingContainer = styled.section`
  justify-items: center;
  align-items: center;
  background: none;
`
const LoaderContainer = styled.div`
  background: none;
  backdrop-filter: opacity(0);
  text-align: center;
  padding: 1rem;
`;


export default function Loading() {
  const pulse = useSpring({
    from: { filter: "drop-shadow(0px 0px 15px rgba(39, 189, 142, 0))" },
    to: async next => {
      while (1) {
        await next({
          filter: "drop-shadow(0px 0px 15px rgba(39, 189, 142, 0.9))"
        });
        await next({
          filter: "drop-shadow(0px 0px 15px rgba(39, 189, 142, 0))"
        });
      }
    },
    config: config.slow
  });
  return (
    <LoadingContainer>
      <LoaderContainer >
        <animated.img style={pulse} src={Jeff} alt="loader"/>
        <animated.h1 style={pulse}>
          welcome
        </animated.h1>
      </LoaderContainer>
    </LoadingContainer>
  )
}
