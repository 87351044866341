import React,  { Suspense, lazy }  from "react";
import ReactDOM from 'react-dom'
import { BrowserRouter } from "react-router-dom";
import Loading from './pages/Loading'
import GlobalStyles from "./style-utils/globalStyles";
import './style-utils/font.css'

const App = lazy(async () => {
  console.log('app loaded')
  return await Promise.all([
    import("./App"),
    new Promise(resolve => setTimeout(resolve, 2000))
  ]).then(([moduleExports]) => moduleExports);
})

ReactDOM.render(
  <>
    <GlobalStyles />
    <Suspense fallback={<Loading/>}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
